import { SimpleCard } from "modules/picasso-ui/present/dashboard/SimpleCard"
import { RankingTemplate } from "../../backlog/rating/RankingTypes"
import { rankingTemplates } from "../../room/useRoomCreate"
import { OptionDesign } from "../answering/RankingAnsweringTypes"
import { RankingPairwiseThumbnail, ScoringThumbnail } from "./RankingCreateTemplatePicker"
import { ResponsiveFlexBox } from "modules/picasso-ui/dataviews/grid/ResponsiveFlexBox"
import { Box, ButtonProps, Typography } from "@mui/material"
import { ElementLink, LinkNormal } from "modules/picasso-ui/link/LinkNormal"
import { Button } from "@mui/material"
import { appRoutes } from "utils/appRoutes"
import { useTranslation } from "next-i18next"

const templatesToShow = ['pairwiseRanking', 'pairwiseRankingImages', 'ice', 'rice']

export const RankingsTemplatesPickerLandingPage = ({locale, onClick, showLearnMoreLink, texts, buttonProps}:{locale?: string, onClick?:(t:RankingTemplate)=>void, showLearnMoreLink?: boolean, texts?: { create?: string }, buttonProps?: ButtonProps}) => {

    const { t } = useTranslation(['vsprio','common'])

    locale = locale || 'en'

    const t2 = {
        de: {
            create: 'Vorlage verwenden'
        },
        en: {
            create: 'Get started'
        }
    }

    const getText = (key) => {
        return texts?.[key] || t2[locale][key]
    }

    const templates = templatesToShow.map(templateId=>rankingTemplates.find(t=>(t.templateId===templateId)||null) as RankingTemplate).filter(t=>t!==null) || ([] as RankingTemplate[])

    const renderPreview = (template: RankingTemplate) => {
        if (template.data?.backlogType === 'ROOM') {
            const variant = template.data?.ratingSettings?.designSettings?.optionDesign === OptionDesign.imageCover ? 'images' : null
            const ranking = template.thumbnailRanking || null
            return <RankingPairwiseThumbnail 
                background="transparent" 
                width="100%" 
                height="310px" 
                maxWidth="100%" 
                variant={variant} ranking={ranking} themeKey="gradientLightPurple" imageHeight="80px" imageWidth="80px" questionColor="#4f5662" 
                style={theme=>({
                    [theme.breakpoints.down('sm')]: {
                        padding: theme.spacing(1) + ' !important',
                    }
                })}
                />
        }
        else if (template.data?.backlogType === 'JIRA') {
            return <ScoringThumbnail background="transparent" width="100%" height="310px" maxWidth="100%" imageSrc={template.thumbnailSrc} themeKey="gradientLightPurple" pt={0} pb={1} imageWrapperProps={{style:{background: 'transparent', '& img': {borderRadius: '3px'}}}} />
        } 
        else {
            return null
        }
    }

    const templateSuffix = t('vsprio:ranking.templates.collection.title')
    const learnMoreText = t('common:other.learnMore')

    return <ResponsiveFlexBox itemsCount={templates.length} cols={2} justifyContent="center" flexDirectionColumnDown="md">
        {templates.map(template=>{

            const title = (template.i18n?.[locale]?.title || template.title) + ' ' + templateSuffix
            const moreUrl = template.i18n?.[locale]?.moreUrl || template.moreUrl

            //@ts-ignore
            return <SimpleCard 
                style={{padding: 0, maxWidth: '100%', background: '#f5f6f8', boxShadow: 'none'}} 
                cardContentStyle={{padding: 0, paddingBottom: '0 !important', alignItems: 'flex-start'}} 
                key={template.templateId}>
                    
                    <Box display="flex" flexDirection="column" justifyContent="space-between" pl={5} pt={5} pb={5} alignSelf="stretch">
                        <Typography variant="h3" width="170px" fontSize="1.75em" fontWeight={700} lineHeight="1.4" color="#061b32" mb={4}>{title}</Typography>
                        <div>
                            {onClick 
                                ?  <>
                                    {/** @ts-ignore because hoverInvert */}
                                    <Button color="primary" variant="contained" hoverInvert={true} onClick={()=>onClick(template)} {...buttonProps}>{getText('create')}</Button>
                                </>
                                : <ElementLink disableClientSideNav href={appRoutes.yoio.access.signup.buildPath('tmpl'+template.templateId)}>
                                    {/** @ts-ignore because hoverInvert */}
                                    <Button color="primary" variant="contained" hoverInvert={true} {...buttonProps}>{getText('create')}</Button>
                                </ElementLink>
                            }
                            {showLearnMoreLink && moreUrl && 
                                <>
                                    {/** @ts-ignore */}
                                    <Box mt={1}><LinkNormal href={moreUrl||'#'} textSx={{fontSize: '12px', color: '#949cb5'}}>{learnMoreText}</LinkNormal></Box>
                                </>
                            }
                        </div>
                    </Box>

                    <Box flex="1 1 auto" textAlign="center">
                        <Box sx={theme=>({display: 'flex', justifyContent: 'center', transform: 'translateY(40px)', [theme.breakpoints.down('sm')]: {display: 'none'}})}>{renderPreview(template)}</Box>
                    </Box>
            </SimpleCard>
        })}
    </ResponsiveFlexBox>

}