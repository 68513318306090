import { Box, CircularProgress, circularProgressClasses, Typography } from "@mui/material";
import { Absolute } from "modules/picasso-ui/layout/Absolute";
import { VerticalAlignBox } from "modules/picasso-ui/layout/VerticalAlignBox";
import { createAnimationBounceCss } from "modules/picasso-ui/present/effect/animationUtils";
import { useImageOnLoad } from "modules/picasso-ui/present/image/useImageOnLoad";
import { useRaterSection } from "modules/plinzip/component/rating/useRaterSection";
import { useState, forwardRef } from "react";


/**
 * 
 * @param {*} variant 
 *      medium, large
 * @returns 
 */
const ImagePortraitChoiceCard = forwardRef(({title, description, onClick, fullWidth, minHeight, height, maxHeight, width, endContent, label, elevateHighOnHover, assets, isSelected, isProposed, style, objectFit}, ref) => {

   /*  const isMounted = useIsMounted() */

   objectFit = objectFit || 'cover'

   const { getAvailableSpaceForOneCard } = useRaterSection()

    const [isClicked, setClicked] = useState()
    
    const mainImageAsset = assets?.[0]

    const [imageLoading, setImageLoading] = useState(true)

    const onImageLoaded = () => {
        //console.log('loaded')
/*         if (!isMounted()) {
            return false;
        } */
        setImageLoading(false)
    }

    const [imgRef] = useImageOnLoad(onImageLoaded, mainImageAsset?.url||null)
    
    height = height || '762px'
    width = width || '508px'

    const css = [{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        boxShadow: imageLoading ? null:'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px -2px 6px -2px',
        width: fullWidth? '100%':width,
        height,
        minHeight,
        maxWidth: '100%',
        maxHeight: maxHeight || '100%',
        borderRadius: '32px',
        overflow: 'hidden',
        //visibility: imageLoading ? 'hidden': null,
    }]
    
    if (isClicked || isSelected) {
        css.push(createAnimationBounceCss())
    } else {
        if(elevateHighOnHover) {
            css.push({
                transition: '0.3s',
                transform: isClicked ? 'translateY(-6px)':null,
                '&:hover': {
                    transform: 'translateY(-6px)'
                },
            })
        }
    }

    if (!isClicked && isProposed) {
        css.push(theme=>({
            padding: '0px',
            //border: `3px solid ${alpha(theme.palette.primary.main, 0.8)}`,
            boxShadow: `0 0 1px 6px ${theme.palette.primary.main}`,
        }))
    }

    const handleClick = (e) => {
        if (isClicked) {
            return;
        }
        if (onClick) {
            setClicked(true)
            onClick(e)
        }
    }

    if(onClick) {
        css.push((theme)=>({
            position: 'relative',
            cursor: !isClicked ? 'pointer':null,
/*             '&:after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                borderRadius: '8px',
                left: '0',
                top: '0',
                width: '100%',
                height: '100%',
                opacity: '0',
                transition: 'all 0.5s',
                boxShadow: `0 0 5px 20px ${theme.palette.primary.main}`,
            }, */
            '&:active:after': {
                boxShadow: `0 0 0 0 ${theme.palette.primary.main}`,
                position: 'absolute',
                borderRadius: '8px',
                left: '0',
                top: '0',
                opacity: '1',
                transition: '0s',
            },
        }))
    }

    if (style) {
        css.push(style)
    }

    return (
        <Box css={css} onClick={onClick?handleClick:null}>
            <Box sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                }}
                p={3}
                >
            {label && 
                <Box mb={3} ml={-3}>
                    <Box sx={(theme)=>({
                        display: 'inline-block',
                        color: '#fff',
                        borderRadius: '4px',
                        borderTopLeftRadius: '0px',
                        borderBottomLeftRadius: '0px',
                        backgroundColor: 'rgb(79, 70, 229)',
                        fontWeight: '600',
                        padding: `4px 16px 4px ${theme.spacing(3)}`,
                        fontSize: '0.9em',
                    })}>
                    {label} 
                    </Box>
                </Box>
            }
{/*                 <Box display="flex" flexDirection="column" flexGrow={1}>
                    <Box>
                        <Typography fontWeight={700} fontSize="1.4em" sx={{minHeight: '3em',}}>
                            {title}
                        </Typography>
                    </Box>
                    <Box mt={1} flexGrow="1" color="text.secondary">
                        <Typography sx={{minHeight: '6em',}}>
                            {description}
                        </Typography>
                    </Box>
                    {endContent}
                </Box> */}
            </Box>
            {mainImageAsset 
                ? <Box
                    className="img-wrapper"
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        flexBasis: 0,
                        minHeight: 0,
                        flexGrow: 1,
                    }}
                    ref={imageLoading ? null:ref}
                >
                    {imageLoading && <VerticalAlignBox height="100%" justifyContent="center" width={getAvailableSpaceForOneCard() || '200px'} maxWidth="100%" position="relative">
                        <Absolute center><CircularProgress size={18} sx={{[`& .${circularProgressClasses.circle}`]: {color: '#d2d8de'}}}/></Absolute>
                    </VerticalAlignBox>}
                    {/** src is set by imgRef */}
                    <img
                        alt=""
                        css={{
                            width: '100%',
                            height: imageLoading ? '1px' : '100%',
                            objectFit,
                            minHeight: 0,
                            minWidth: 0,
                            maxWidth: 'min-content',
                            opacity: imageLoading ? 0:1
                        }}
                        ref={imgRef}
                    />
                </Box>
                : <VerticalAlignBox ref={ref} width="200px" maxWidth="100%" color="text.secondary" justifyContent="center" height="100%" backgroundColor="#efefef"><Typography component="div">No image</Typography></VerticalAlignBox>
            }
            {endContent && (
                <Absolute bottom="10px" right="10px">{endContent}</Absolute>
            )}
        </Box>
    )

})

ImagePortraitChoiceCard.displayName ='ImagePortraitChoiceCard'
export { ImagePortraitChoiceCard as ImagePortraitChoiceCard }