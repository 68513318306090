import { useIsMounted } from "modules/picasso-ui/form/useIsMounted"
import { hideAppProgressIndicator, showAppProgressIndicator } from "modules/picasso-ui/main/AppProgressIndicator"
import { notifyWindowEvent } from "modules/picasso-ui/state/windowEventUtils"
import { createBacklog, createGuestBacklog } from "modules/plinzip/api/backlogApi"
import { useRouter } from "next/router"
//import alerts from "state/alerts"
import { entityId } from "utils/apiUtils"
import { appRoutes } from "utils/appRoutes"
import { notNullNotUndefined } from "utils/objectUtils"
import { RankingEvent } from "../backlog/rating/RankingTypes"
import { OptionDesign } from "../rankings/answering/RankingAnsweringTypes"

/**
 * 'display name': just used to show in list, not used for creation
 * 'name' is used for room name on creation
 * 'description' is only used as explainer in landing page. Not used to create the room.
 */
export const rankingTemplates = [
    {
        templateId: 'pairwiseRanking',
        title: 'Pairwise Ranking',
        description: 'Pairwise Ranking',
        helperText: 'Participants have to compare pairs of items with each other. Our algorithm will show pairs to a participant until we are a clear what his priorities are.',
        moreUrl: '/tools/pairwise-ranking-tool',
        data: {
            backlogType: 'ROOM',
        },
        i18n: {
            de: {
                title: "Paarweiser Vergleich",
                description: 'Paarweises bewerten',
                helperText: 'Teilnehmer müssen Alternativen im Direktvergleich bewerten. Unser Algorithmus wird einem Teilnehmer nach und nach Paare zeigen, bis unser System die Präferenzen des Teilnehmers versteht.',
                moreUrl: '/de/tools/paarweiser-vergleich-online',
            }
        }
    },
    {
        templateId: 'pairwiseRankingImages',
        title: 'Image Ranking',
        description: 'Pairwise Ranking',
        helperText: 'Participants have to compare pairs of items with each other. Our algorithm will show pairs to a participant until we are a clear what his priorities are.',
        moreUrl: '/tools/image-ranking-survey',
        thumbnailRanking: {
            question: 'Which logo is better?',
            pair: {
                left: {
                    name: 'Logo 1',
                    assets: [{url: '/vsprio/backlog/create/templates/rankingPairwise-logo1-item1-image.jpg'}],
                },
                right: {
                    name: 'Logo 2',
                    assets: [{url: '/vsprio/backlog/create/templates/rankingPairwise-logo1-item2-image.jpg'}],
                }
            },
        },
        data: {
            backlogType: 'ROOM',
            ratingSettings: {
                designSettings: {
                    optionDesign: OptionDesign.imageCover
                }
            }
        },
        i18n: {
            de: {
                title: "Paarvergleich Bilder",
                helperText: 'Teilnehmer müssen Bilder im Direktvergleich bewerten. Unser Algorithmus wird einem Teilnehmer nach und nach Bilder-Paare zeigen, bis unser System die Präferenzen des Teilnehmers versteht.',
                description: 'Paarweises bewerten',
            }
        }
    },
    {
        templateId: 'ice',
        title: 'ICE Prioritization',
        description: 'Weighted Scoring',
        helperText: 'Decision matrix with the criteria Impact, Confidence, Effort. You can modify the criteria and customize the scoring formula.',
        thumbnailSrc: '/vsprio/backlog/create/templates/ice-preview.png',
        moreUrl: '/tools/ice-prioritization-tool',
        data: {
            backlogType: 'JIRA',
        },
        i18n: {
            de: {
                title: "ICE Priorisierung",
                description: 'Entscheidungsmatrix',
                helperText: 'Entscheidungsmatrix mit den Kriterien Impact, Confidence, Effort. Du kannst die Kriterien ändern und die Bewertungsformel anpassen.',
            }
        }
    },
    {
        templateId: 'rice',
        title: 'RICE Prioritization',
        description: 'Weighted Scoring',
        helperText: 'You can modify the criteria and customize the scoring formula.',
        thumbnailSrc: '/vsprio/backlog/create/templates/rice-preview.png',
        moreUrl: '/templates/rice-prioritization',
        data: {
            backlogType: 'JIRA',
        },
        i18n: {
            de: {
                title: "RICE Priorisierung",
                description: 'Entscheidungsmatrix',
                helperText: 'Entscheidungsmatrix mit den Kriterien Reach, Impact, Confidence, Effort. Du kannst die Kriterien ändern und die Bewertungsformel anpassen.',
            }
        }
    },
    {
        templateId: 'decisionMatrixCustom',
        title: 'Custom Decision Matrix',
        description: 'Weighted Scoring (Reach, Impact, Confidence, Effort)',
        helperText: 'You can modify the criteria and customize the scoring formula.',
        thumbnailSrc: '/vsprio/backlog/create/templates/rice-preview.png',
        data: {
            backlogType: 'JIRA',
        },
        i18n: {
            de: {
                title: "Eigene Entscheidungsmatrix"
            }
        }
    },
    {
        templateId: 'decisionMatrixCustom2',
        title: 'Custom Decision Matrix',
        description: 'Weighted Scoring',
        helperText: 'Custom criteria and custom scoring formula.',
        thumbnailSrc: '/vsprio/backlog/create/templates/rice-preview.png',
        data: {
            backlogType: 'JIRA',
        },
        i18n: {
            de: {
                title: "Nutzwertanalyse",
                description: 'Entscheidungsmatrix',
                helperText: 'Benutzerdefinierte Kriterien und eigene Gewichtung.',
            }
        }
    },
    {
        templateId: 'default',
        title: 'Empty room',
        data: {
            backlogType: 'ROOM',
            name: 'Untitled ranking',
        }
    },
    {
        templateId: 'saasMarketing',
        data: {
            backlogType: 'ROOM',
            name: 'Marketing Strategy',
            description: 'Select a marketing activity to focus on.',
            items: [
                {   
                    name: 'Give presentations at conferences',
                },
                {   
                    name: 'Student discount promotion',
                },
                {   
                    name: 'Create a learning course',
                },
                {
                    name: 'Weekly blog article about tool',
                },
                {
                    name: 'Weekly blog article about industry happenings',
                },
                {   
                    name: 'Daily social media post',
                },
                {
                    name: 'Exhibit at events',
                },
                {   
                    name: '5-minute videos',
                },
                {   
                    name: 'Newsletter',
                },
                {
                    name: 'Search Engine Advertising',
                },
                {
                    name: 'Paid promotions to get named in popular articles',
                },
                {
                    name: 'Affiliate Marketing',
                },
                {
                    name: 'Podcast Sponsoring',
                },
                {
                    name: 'SEO',
                },
                {
                    name: 'Influencer campaign',
                },
                {
                    name: 'Engage in other social media posts',
                },
                {
                    name: 'Ebooks & Whitepaper',
                },
            ]
        }
    },
    {
        backlogType: 'ROOM',
        templateId: 'product',
        title: 'Product Prioritization',
        data: {
            name: 'Product Prioritization',
            description: 'Decide your next product features.',
            items: [
                {
                    name: 'Mobile version',
                },
                {
                    name: 'Integration of service X',
                },
                {
                    name: 'Social login',
                },
                {
                    name: 'Reporting redesign',
                },
                {
                    name: 'Custom templates',
                },
                {
                    name: 'Offer discount during cancellation',
                }
            ],
        },
    },
    {
        backlogType: 'ROOM',
        templateId: 'technicalDebt',
        title: 'Biggest Development Headache',
        description: 'Save time and money by eliminating your development team\'s biggest roadblock.',
        data: {
            name: 'Biggest Development Headache',
            items: [
                {
                    name: 'Manual releasing (missing CI/CD)',
                },
                {
                    name: 'Missing unit & integration tests',
                },
                {
                    name: 'Manual activities that should be features',
                },
                {
                    name: 'Support',
                },
                {
                    name: 'Cloud provider costs',
                },
                {
                    name: 'Old technology',
                },
                {
                    name: 'Staging/production differences',
                },
            ],
        }
    },
    {
        backlogType: 'ROOM',
        templateId: 'workplacePerks',
        title: 'Workplace Benefits',
        description: 'Know which workplace benefit matters most to your employees.',
        data: {
            name: 'Workplace Benefits',
            items: [
                {   
                    name: 'Educational budget for books and courses.',
                },
                {   
                    name: 'Mobile phones',
                },
                {   
                    name: 'Public transport ticket',
                },
                {   
                    name: 'Paid leave',
                },
                {   
                    name: 'Time of for personal duties',
                },
                {   
                    name: 'Long vacation times',
                },
                {   
                    name: 'Remote work',
                },
                {   
                    name: 'Fitness and wellness benefits',
                },
                {   
                    name: 'Skills development at work',
                },
                {   
                    name: 'Food and beverages',
                }
                
            ]
        }
    }
]

export const useRoomCreate = (showIndicator) => {//variant: 'workspace', 'guest'

    const isMounted = useIsMounted()

    const router = useRouter()

    const create = (variant, backlogId, templateId, otherData, apiTemplateId, joinAsGuest, silent) => {
        notNullNotUndefined(backlogId)
        notNullNotUndefined(variant)
        
        if (showIndicator) {
            showAppProgressIndicator()
        }

        templateId = templateId || 'default'

        const template = rankingTemplates.find(t=>t.templateId===templateId)
        if (!template) {
            throw Error('unknown templateId')
        }

        const data = {
            backlogId,
            name: template.name,
            items: template.items ? template.items.map(i=>({
                itemId: entityId(),
                name: i.name,
            })): null,
            type: 'ROOM',
            ratingQuestion: 'Which option is better?',
            templateId: apiTemplateId,
            ...otherData
        }



        if (variant === 'guest') {
            return createGuestBacklog(data)
                .then((res)=> {
                    if (silent) {
                        return res.data;
                    }
                    setTimeout(()=>{
                        hideAppProgressIndicator()
                        /* if (isMounted()) { */
                            if (joinAsGuest) {
                                router.push(appRoutes.vsprio.rooms.buildPath(res.data.alias))
                            } else {
                                router.replace(appRoutes.backlogsGuest.roomAsAdmin.buildPath(res.data.backlogId, res.data.magicKey))
                            }
                       /*  } */
                    }, 1500)
                })
                .catch(()=> {
                    if (silent) {
                        return;
                    }
                    hideAppProgressIndicator()
                    //alerts.error('unexpected error. please reload page.')
                })
        }
        else if (variant === 'workspace') {
            return createBacklog(data)
                .then(()=> {
                    if (silent) {
                        return;
                    }
                    setTimeout(()=>{
                        hideAppProgressIndicator()
                        if (isMounted()) {
                            router.push(appRoutes.vsprio.rooms.buildPath(data.backlogId))
                        }
                    }, 1500)
                })
                .catch(()=> {
                    if (silent) {
                        return;
                    }
                    hideAppProgressIndicator()
                    //alerts.error('unexpected error. please reload page.')
                })
        } else {
            throw Error('unexpected state')
        }

    }

    const gotoMakeACopy = (backlogId, workspaceId, type, name, itemsCount) => {
        notNullNotUndefined(backlogId)
        notNullNotUndefined(workspaceId)
        notNullNotUndefined(type)
        notNullNotUndefined(name)
        notNullNotUndefined(itemsCount)

        notifyWindowEvent(RankingEvent.rankingCopyCalled, {
            backlogId,
            workspaceId,
            type,
            name,
            itemsCount,
        })
    }

    return { create, gotoMakeACopy }

}