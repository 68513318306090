import { useEffect, useRef } from "react";

/**
 * 
 * @param {*} callback 
 * @param {*} src 
 *    Pass the src here instead setting it on the image directly.
 *    Src attribute must be set after load callback to guarantee that load is fired.
 * @returns 
 */
export const useImageOnLoad = (callback, src) => {

    const imgRef = useRef()

    useEffect(() => {
        if (!imgRef.current) {
            return;

        }

        if (imgRef.current) {
            imgRef.current.addEventListener('load', callback);
            if (src) {
              imgRef.current.src = src
            }
          return () => {
            if (imgRef.current) {
              imgRef.current.removeEventListener('load', callback)
            }
          }
        }
      }, [imgRef]);

      return [imgRef]

}