import { Box, BoxProps, Button, Divider, Typography } from "@mui/material"
import { ChoiceCards } from "modules/picasso-ui/form/ChoiceCards"
import { VerticalAlignBox } from "modules/picasso-ui/layout/VerticalAlignBox"
import { CardTw } from "modules/picasso-ui/present/card/CardTw"
import { RaterDemo } from "modules/plinzip/component/rating/Rater"
import Image from "next/image"
import { FunctionComponent, useState, ReactNode, useTransition } from "react"
import { RankingTemplate } from "../../backlog/rating/RankingTypes"
import { rankingTemplates } from "../../room/useRoomCreate"
import { OptionDesign } from "../answering/RankingAnsweringTypes"
import { ImagePortraitChoiceCard } from "../answering/rating/ImagePortraitChoiceCard"
import { RANKING_THEMES } from "../useRankingDesign"
import { orElse } from "utils/objectUtils"
import { useTranslation } from "next-i18next"

export interface RankingCreateTemplatePickerProps {
    onChange?: (template?: RankingTemplate)=>void 
    renderButton?: (props:any)=>ReactNode
    initialSelected?: RankingTemplate
}

const templatesToShow = ['pairwiseRanking', 'pairwiseRankingImages', 'ice', 'rice', 'decisionMatrixCustom2']

export const RankingCreateTemplatePicker: FunctionComponent<RankingCreateTemplatePickerProps> = ({onChange, renderButton, initialSelected}) => {

    const { t, i18n } = useTranslation('common')

    const templates = templatesToShow.map(templateId=>rankingTemplates.find(t=>(t.templateId===templateId)||null) as RankingTemplate).filter(t=>t!==null) || ([] as RankingTemplate[])

    const templatesAsOptions = templates.map(t=>({
        title: t.i18n?.[i18n.language]?.title || t.title,
        //description: (t.data?.backlogType === 'ROOM' ? 'Pairwise Ranking':null) || (t.data?.backlogType === 'JIRA' ? 'Weighted Scoring':null),
        thubnailSrc: t.thumbnailSrc,
        key: t.templateId,
        value: t.templateId,
    }))

    const [selected, setSelected] = useState<RankingTemplate>(initialSelected||null)
    
    const renderPreview = (template: RankingTemplate) => {
        if (template.data?.backlogType === 'ROOM') {
            const variant = template.data?.ratingSettings?.designSettings?.optionDesign === OptionDesign.imageCover ? 'images' : null
            const ranking = template.thumbnailRanking || null
            return <RankingPairwiseThumbnail width="380px" height="250px" maxWidth="100%" variant={variant} ranking={ranking}/>
        }
        else if (template.data?.backlogType === 'JIRA') {
            return <ScoringThumbnail width="380px" height="250px" maxWidth="100%" imageSrc={template.thumbnailSrc}/>
        } 
        else {
            return null
        }
    }


    /**
     * Actions
     */

    const handleChange = (options) => {
        const selectedKey = options?options.find(o=>o.isSelected)?.key:null

        const updated = selectedKey?templates.find(t=>t.templateId===selectedKey):null
        
        onChange?.(updated)
        setSelected(updated)
    }




    /**
     * Render
     */

    const description = (selected?.data?.backlogType === 'ROOM' ? 'Pairwise Ranking':null) || (selected?.data?.backlogType === 'JIRA' ? 'Weighted Scoring':null)

    renderButton = renderButton || ((props:any) => {
        return <Button {...props}/>
    })

    return (
        <Box>
            <Box sx={{
                transform: 'scale(0.95)',
                transformOrigin: 'top left',
            }}>
                <Box maxWidth="100%" sx={{overflowX:'auto'}} pb={2}>
                    <Box width="max-content">
                        <ChoiceCards 
                            single 
                            minSelected={1}
                            choiceCardVariant="box" 
                            options={templatesAsOptions} 
                            defaultSelected={initialSelected && templatesAsOptions ? [templatesAsOptions.find(t=>t.key===initialSelected.templateId)] : null}
                            showCheckbox={false}
                            onChange={handleChange}
                            styling={{
                                choiceCard: {
                                    height: '58px',
                                    width: 'initial',
                                    paddingLeft: '16px',
                                    paddingRight: '16px',
                                },
                                title: {
                                    fontWeight: '600',
                                    fontSize: '1.05em',
                                },
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box pb={2}>
                <Divider />
            </Box>
            {selected && 
                <Box pt={3}>
                         {/**@ts-ignore */}
                        <Box>
                            <Box color="text.primary">
                                 {/**@ts-ignore */}
                                <Box display="flex" alignItems="flex-start" sx={theme=>({[`${theme.breakpoints.down('md')}`]:{ flexDirection: 'column', alignItems: 'stretch', }})}>
                                    <Box flex={1} pt={2}>
                                        <Typography fontSize="2.2em" mb={4}>{selected.i18n?.[i18n.language]?.title || selected.title}</Typography>
                                        <Typography component="div" color="text.secondary" fontWeight={500}>{t('vsprio:ranking.templates.item.method')}: {selected.i18n?.[i18n.language]?.description || selected.description}</Typography>
                                        {selected.helperText && <Typography component="div" mt={1} color="text.secondary" sx={{maxWidth: '500px'}}>{selected.i18n?.[i18n.language]?.helperText || selected.helperText}</Typography>}
                                        <Box mt={2} mb={1}>{renderPreview(selected)}</Box>
                                        <Box mt={8}>
                                            {renderButton({
                                                sx: {width: '380px', maxWidth: '100%',},
                                                size: 'large',
                                                color: 'primary',
                                                variant: 'cointained',
                                                children: t('vsprio:ranking.templates.picker.useTemplate')
                                            })}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                </Box>
            }
        </Box>
    )

}

interface RankingPairwiseThumbnailProps {
    ranking?: any 
    variant?: string
    themeKey?: string
    background?: string
    imageHeight?: string
    imageWidth?: string
    questionColor?: string
    style?: any
}

export const RankingPairwiseThumbnail: FunctionComponent<RankingPairwiseThumbnailProps & BoxProps> = ({ranking, variant, themeKey, background, imageHeight, imageWidth, questionColor, style, ...props}) => {

    variant = variant || 'card'

    ranking = ranking || {}
    ranking.question = ranking.question || 'Which option is better?'

    imageHeight = imageHeight || "120px"
    imageWidth = imageWidth || "100%"

    themeKey = themeKey || 'gradientBlueLight'

    const renderCard = (props) => {
        if (variant === 'images') {
            return <ImagePortraitChoiceCard 
                {...props} 
                height={imageHeight}
                width={imageWidth}
                style={[props.sx,{background: '#eee', borderRadius: '10px', boxShadow: 'none'}]}
            />
        }
        return <CardTw 
            {...props} 
            fullWidth
            sx={[props.sx,{height: '110px', background: '#fff', boxShadow: 'none'}]}
        />
    }
    
    return (
        <Box p={4} pt={6} {...props} sx={[{borderRadius: '6px', background: background || RANKING_THEMES[themeKey]?.answeringSection?.background, pointerEvents: 'none',}, style]}>
            <Box textAlign="center"><Typography fontWeight="400" color={questionColor || '#fff'} fontSize="1em">{ranking.question}</Typography></Box>
            {/**@ts-ignore */}
            <VerticalAlignBox mt={2}>
                <Box sx={{transform: variant==='card'?'scale(0.75)':null, width: '100%', transformOrigin: 'top center'}}>
                     {/**@ts-ignore */}
                    <RaterDemo pair={ranking.pair} hideSlider renderCard={renderCard} choiceCardsAlignment={variant==='images'?'center':null}/>
                </Box>
            </VerticalAlignBox>
        </Box>
    )

}

interface ScoringThumbnailProps {
    imageSrc?: string
    themeKey?: string
    background?: string
    imageWrapperProps?: any
}

export const ScoringThumbnail: FunctionComponent<ScoringThumbnailProps & BoxProps> = ({imageSrc, themeKey, background, p, imageWrapperProps, ...props}) => {

    themeKey = themeKey || 'gradientBlueLight'

    return (
        <Box p={orElse(p,4)} sx={{borderRadius: '6px', background: background || RANKING_THEMES[themeKey]?.answeringSection?.background, pointerEvents: 'none',}} {...props}>
            <Box padding={2} height="100%" {...imageWrapperProps} sx={[{background: '#fff', borderRadius: '6px', overflow: 'hidden',}, imageWrapperProps?.style]}>
                <Box position="relative" height="100%">
                    <Image src={imageSrc} layout="fill" objectFit="contain" objectPosition="center center"/>
                </Box>
            </Box>
        </Box>
    )

}