import { keyframes } from "@mui/system";

const pressKeyframes = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.92);
  }
  to {
    transform: scale(1);
  }
`;

const bounceKeyframes = keyframes`
  50% {
    transform: rotate(5deg) translate(10px, -100px);
  }
  to {
    transform: scale(0.9) rotate(10deg) translate(10px, -150px);
    opacity: 0;
  }
`;

export const createAnimationBounceCss = () => {
    return {
        animation: `${bounceKeyframes} 0.4s ease forwards`,
    }
}