import { Box, Typography, alpha } from "@mui/material";
import { FC, MouseEvent, ReactNode, useState } from "react";
import { createAnimationBounceCss } from "../effect/animationUtils";


const styles = {
    card: {
        transition: '0.3s',
    },
    cardElevateHigh: {
        transform: 'translateY(-6px)'
    },
}

export interface CardTwProps {
    title?: string
    description?: string
    onClick?: (e: MouseEvent<HTMLElement>) => void
    fullWidth?: boolean
    height?: string | number
    embedded?: boolean
    endContent?: ReactNode
    endContentOnBorder?: ReactNode
    label?: string
    elevateHigh?: boolean
    elevateHighOnHover?: boolean
    titleProps?: any
    sx?: any
    isSelected?: boolean
    isProposed?: boolean
}

/**
 * 
 * @param {*} variant 
 *      medium, large
 * @returns 
 */
export const CardTw: FC<CardTwProps> = ({title, description, onClick, fullWidth, height, embedded, endContent, endContentOnBorder, label, elevateHigh, elevateHighOnHover, titleProps, sx, isSelected, isProposed}) => {
   
    const [isClicked, setClicked] = useState<boolean>()

    const css = [ styles.card, {
        boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px -2px 6px -2px',
        borderRadius: '8px',
        width: fullWidth? '100%':null,
        height,
        padding: '3px',
    }, ...(Array.isArray(sx) ? sx : [sx])]

    if (elevateHigh) {
        css.push(styles.cardElevateHigh)
    }

    if (isClicked || isSelected) {
        css.push(createAnimationBounceCss())
    } else {
        if(elevateHighOnHover) {
            css.push({
                transition: '0.3s',
                transform: isClicked ? 'translateY(-6px)':null,
                '&:hover': {
                    transform: 'translateY(-6px)'
                },
            })
        }
    }

    const cssContent = [{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    }] as any[]

    const handleClick = (e) => {
        if (isClicked) {
            return;
        }
        if (onClick) {
            setClicked(true)
            onClick(e)
        }
    }

    if(onClick) {
        css.push((theme)=>({
            position: 'relative',
            cursor: !isClicked ? 'pointer':null,
            '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                borderRadius: '8px',
                left: '0',
                top: '0',
                width: '100%',
                height: '100%',
                opacity: '0',
                transition: 'all 0.5s',
                boxShadow: `0 0 5px 20px ${theme.palette.primary.main}`,
            },
        }))
        css.push(theme=>({
            '&:active::before': {
                boxShadow: `0 0 0 0 ${theme.palette.primary.main}`,
                position: 'absolute',
                borderRadius: '8px',
                left: '0',
                top: '0',
                opacity: '1',
                transition: '0s',
                //display: isClicked ? 'block':'none',
            },
        }))

        // 
        if(!isClicked) {
            // When there is onOnlick, then 'hover' should act like 'proposed'
            css.push(theme=>({
                '&:hover': {
                    padding: '0px',
                    border: `3px solid ${alpha(theme.palette.primary.main, 0.8)}`,
                    transition: 'none',
                }
            }))
        }
    } 
    if (!isClicked && isProposed) {
        css.push(theme=>({
            padding: '0px',
            border: `3px solid ${alpha(theme.palette.primary.main, 0.8)}`,
            transition: 'none',
        }))
    }


    return (
        <Box css={css} onClick={onClick?handleClick:null} >
            <Box css={cssContent} p={3}>
                {label && 
                    <Box mb={3} ml={-3}>
                        <Box sx={(theme)=>({
                            display: 'inline-block',
                            color: '#fff',
                            borderRadius: '4px',
                            borderTopLeftRadius: '0px',
                            borderBottomLeftRadius: '0px',
                            backgroundColor: 'rgb(79, 70, 229)',
                            fontWeight: '600',
                            padding: `4px 16px 4px ${theme.spacing(3)}`,
                            fontSize: '0.9em',
                        })}>
                        {label} 
                        </Box>
                    </Box>
                }
                    <Box display="flex" flexDirection="column" flexGrow={1}>
                        <Box>
                            <Typography component="div" fontWeight={700} fontSize="1.4em" sx={{minHeight: '3em',}} {...titleProps}>
                                {title}
                            </Typography>
                        </Box>
                        <Typography component="div" color="text.secondary" sx={{height: embedded ? '2em' : '6em', mt: 1, flexGrow: 1, flexShrink: 2}}>
                            {description}
                        </Typography>
                        {endContent}
                    </Box>
            </Box>
            {endContentOnBorder}
        </Box>
    )

}